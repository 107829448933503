Bu sayfadaki puf noktalar:
1 - Bos
<template>
    <div v-cloak  >



        <div class="slider">
        <vueper-slides v-if="slider.length  > 0"
        :autoplay=true
        fractions
        :pause-on-hover=false
        :dragging-distance=70
        :prevent-y-scroll=true
        :slide-ratio="500/1000"
        :duration=5000
        :transition-speed='850'
        
       

    

        >
  <vueper-slide  v-for="(item, i) in slider"
   :key="i"
   >


   <template #content>
      <div class="vueperslide__content-wrapper" >
       <img :src="'https://adadakiler.zdc.com.tr/img/banner/'+item.id+'.png?'+item.imageupdate">
      </div>
    </template>


  </vueper-slide>
</vueper-slides>


</div>
    
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
    components: { VueperSlides, VueperSlide },

    data(){
        return{
            slide : [], // sorgudan donen bilgi buraya yazilir
            errors : [],
            slider : [], // slide id-leri tek tek bu diziye atilir ve gosterme islemi bu dizi uzerinden yapilir
       
 
            // cookie bilgileri START
            utel : "",
            uid : "",
            // cookie bilgileri END
          
            koordinat : "",
        }
    },
   
    computed : {
        ...mapGetters(["serverRequest"]),
        currentImg(){
            return this.slider[Math.abs(this.currentIndex) % this.slider.length]
        }
    },
    methods : {
        
        async fetchSlide(){
            this.getKoordinat()
            this.getUid_Udep()
            try {
                const formData = new FormData();
                formData.append('f', 'web_get_init_data');
                formData.append('depo', this.koordinat);
                formData.append('os', 'web');
                formData.append('version', '1');
                formData.append('fbid', '0');
                formData.append('teslimattip', '1');
                var getUserInLocal = localStorage.getItem("user")
                if(getUserInLocal != null){ // kullanici kaydi varsa. kontrol localdeki 'user'-e gore yapiliyor
                    formData.append('user', this.utel);
                    formData.append('uid', this.uid);
                }
                const response = await axios.post(this.serverRequest,formData,{
                    headers: {
                        // 'Accept': 'application/json',
                        // "Access-Control-Allow-Origin": "*",
                        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
                this.slide = response.data
                var banners = [];
                this.slide.map((item) => {
                    banners.push(item.banners)
                })
                banners[2].map((item) => {
                    this.slider.push(item)
                })

             
           
            }catch (error) {
                this.errors.push(error)
            }
        },
        getKoordinat(){
            this.koordinat = localStorage.getItem("koord")
        },
        getUid_Udep(){
            this.utel = this.decryptCookie("utel")
            this.uid = this.decryptCookie("uid")
        },
      
    },
    created(){
        this.fetchSlide()
      
    }
}
</script>

<style>


.vueperslides__bullet .default {
  background-color: rgba(255, 255, 255, 0.6);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;

}

.vueperslides__bullet--active .default {background-color: #ff8800;}


</style>


<style scoped>
[v-cloak] > * { display:none; }


img{ 
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

     .slider{
        overflow: hidden;
        position: relative;
        width: 65vw;
        height: 32.5vw; /* same as width */
        border: 1px solid #eee;
        border-radius: 10px;
        margin-bottom: 5px;
        margin-top: 10px;
        object-fit: contain;
    }




    
 @media (max-width : 5000px){
    
   

    
    
    }
    @media (max-width : 990px){
        img{ width: 95vw !important; }

        .slider{
        overflow: hidden;
        position: relative;
        width: 95vw;
        height: 47.5vw; /* same as width */
        border: 1px solid #eee;
        border-radius: 10px;
        margin-bottom: 5px;
        margin-top: 10px;
        object-fit: contain;
    }

        
    }


    
   
  
 
  
</style>

