<template>


     <div  id="grup-basiklari-div"  class="col-lg-12 col-sm-12 col-12 col-md-12">
                      
                        <div class="card text-center sticky mb-4">
                            <div class="card-header white-text urun-gruplari-baslik" id="draggable-header" :style="urunGruplariColor" >
                                KATEGORİLER
                            </div>
                            <div class="card-body grup-basliklari-body" :style="urunGruplari">
                               
                                <div v-for="liste in gruplar" :key="liste.id">
                                    <div v-for="altgrup in liste" :key="altgrup.id">
                                

                                
                                       
                                          <router-link :to="{ name : 'AltgruplarPage', params : {id:altgrup.id, g_ad:altgrup.ad.replace(/\//g, '')} }">
                                              <a href="#!" class="text-dark">
                                            <p class="card-text urun-gruplari-each mt-2 mb-2" :class="urunGruplariEach">{{ altgrup.ad }}</p>
                                              </a>
                                        </router-link>
                                        <router-view />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>



</template>

<script>


import { mapGetters } from 'vuex'
import axios from 'axios'


export default {
      data(){
        return{
            gruplar : [],
            showYukleniyor : true,
            showUrunGruplari : false,
            errors : [], // hata olursa buraya doner
        
          }
    },
  components : {

  },

  created(){ // sayfa render oldugu zaman ilk calisacak metod burasi
        this.fetchGruplar()
  },

  computed : {
    
     ...mapGetters(["urunGruplariColor","urunGruplari","urunGruplariBaslik","urunGruplariEach","serverRequest"])
    

  },

  methods : {
       
        async fetchGruplar(){ // urun gruplari icin
       
       console.log(this.serverRequest)
      
            this.getUid_Udep()
            this.getKoordinat()
          
            try {
                const formData = new FormData();
                formData.append('f', 'get_gruplar');
                formData.append('deposahip', this.ushp);
                const response = await axios.post(this.serverRequest,formData,{
                    headers: {
                    // 'Accept': 'application/json',
                    // "Access-Control-Allow-Origin": "*",
                    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
                this.gruplar = response.data // data() icerisinde de tanimlanan altgrupUrunler array-ine request sonrasi donen bilgiler yazilir
                console.log(response.data)
            } catch (error) {
                this.errors.push(error) // hata mesajlari data() icerisinde de tanimlanan error array-ine yazilir
            }
            if(this.gruplar == ""){
                this.showYukleniyor = false
            }
            // console.log("sorgu yapildi")
        },

         getUid_Udep(){
            this.udep = this.decryptCookie("udep")
            this.ushp = this.decryptCookie("ushp")
        },
         getKoordinat(){
            this.koordinat = localStorage.getItem("koord")
        },

  }
}
</script>



<style scoped>
    [v-cloak] > * { display:none; }
    @media (max-width : 990px){
        #dahaFazlaID{
            margin-bottom: 40px;
        }
        :is(#grup-basiklari-div){
            display: none;
        }
    }
    /* iPad */
    @media (max-width : 780px){
        .resp-urun-baslik{
            font-size: 15px;
        }
        .urun-gruplari-each{
            font-size: 13px !important;
        }
        .resp-urun-button{
            font-size: 8px;
        }
    }
    @media (max-width : 500px){
        .resp-urun-baslik{
            font-size: 20px;
        }
        .text-danger{
            font-size: 13px;
        }
        .text-grey{
            font-size: 13px;
        }
    }
    @media (max-width : 400px){
        .page-bar{
            font-size: 11px;
        }
        .min-height-name{
            font-size: 12px;
            min-height: 26px !important;
            max-height: 26px !important;
        }
        .row .urunler-mobile{
            margin-left: -20px;
            margin-right: -20px;
        }
        .urunler-col{
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    /* iPhone 5/SE */
    @media (max-width : 320px){
        .urunler-mobile .float-left{
            padding: 10px;
        }
        .row .urunler-mobile{
            margin-left: -24px;
            margin-right: -24px;
        }
        .urunler-col{
            margin-left: -7px;
            margin-right: -7px;
        }
    }
    #grup-basiklari-div{
        /* sticky-ler genellikle her sayfada 5 olarak ayarlandi. Urun gruplari div-i suruklenirken,
        Urunler ust bar-inin uzerinde gorunmesi icin zindex 6 olarak ayarlandi burada */
        z-index: 6;
        margin-left: 20px;
        margin-top: 10px;
    }
    .resp-urun-button{
        border-radius: 10px;
    }
    .urunler-col{
        margin-left: -10px;
        margin-right: -10px;
        height: 365px;
    }
    .urun-gruplari-baslik{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 22px 0px 22px 0px;
    }
    #draggable-header {
        cursor: grab;
    }
    #draggable-header:active{
        cursor: grabbing;
    }
    .grup-basliklari-body{
        border-radius: 10px;
    }
    .urun-gruplari-each{
        transition: all 0.1s ease;
    }
    .urun-gruplari-each:hover{
        border: #f3f3f3;
        background-color: #f3f3f3;
    }
    .urun-gruplari-each-dark{
        transition: all 0.1s ease;
    }
    .urun-gruplari-each-dark:hover{
        font-size: 18px;
        border: #DEDEDE;
        background-color: #DEDEDE;
        color: #212121;
    }
    .sayfa-konum-hidden {
        display: none;
        transform: translate3d(0, -100%, 0);
    }
    .page-bar-div{
        border: 1px solid white;
        border-radius: 5px;
        font-size: 13px;
        color: #c4c4c4;
        background-color: white;
        padding: 15px;
        margin-top: 5px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        overflow-x: auto; /* bar-in x ekseninde scroll-u icin */
        white-space: nowrap; /* bar-in x ekseninde scroll-u icin */
    }
    .page-bar{
        border: 1px solid;
        border-radius: 5px;
        padding: 10px;
        color: #c4c4c4;
    }
    .page-bar:hover{
        color: #4285F4;
    }
    .sticky {
        z-index: 5;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 2px;
    }
    .sticky-sayfa-konum{
        z-index: 5;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 80px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .daha-fazla {
        position: sticky;
        top: 2px;
        padding: 5px 10px;
    }
    .min-height-name{
        margin-top: -5px;
        min-height: 40px;
        max-height: 40px;
        overflow: hidden !important;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .float-left{
        display: inline;
        float: left;
    }
    img.first{
        max-width: 100px;
        max-height: 130px;
        object-fit: contain;
    }
    .max-width-img{
        min-height: 150px;
        max-height: 150px;
    }
    .loading{
        width: 17px;
        height: 17px;
    }
    .urunler-adet-input{
        padding-left: 8px;
        display: flex;
        justify-content: center;
    }
    .baslik-dark{
        background-color: #212121;
        color: white;
    }
    .spinner{
        width: 45px;
        height: 45px;
    }

    /* skeleton loader START */
    .skeleton-card{
        height: 250px;
        background-image: linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc),
            linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc),
            linear-gradient(#ccc, #ccc),
            linear-gradient(white, white);
        background-size: 120px 25px, 160px 20px, 100px 15px, 100px 15px, 120px 30px, 190px 20px, 100% 100%;
        background-position: 50% 24px, 50% 85px, 50% 120px, 50% 146px, 50% 200px, 0 0;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    .skeleton-card::before { /* div uzerindeki tarama efekti icin */
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 50px;
        background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
    @keyframes load {
        from {
            left: -10px;
        }
        to   {
            left: 100%;
        }
    }
    .skeleton-card-urunler{
        height: 365px;
        background-image: radial-gradient(circle 35px, #ccc 100%, transparent),
            linear-gradient(#ccc, #ccc),
            linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc),
            linear-gradient(#ccc, #ccc),
            linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc),
            linear-gradient(white, white);
        background-size: 75px 75px, 25% 15px, 14% 15px, 20% 15px, 90% 1px, 30% 15px, 75% 32px, 75% 32px, 100% 100%;
        background-position: 50% 44px, 20% 180px, 50% 180px, 75% 180px, 50% 220px, 50% 245px, 50% 280px, 50% 320px, 0 0;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    .skeleton-card-urunler::before { /* div uzerindeki tarama efekti icin */
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 50px;
        background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
        animation: load-urunler 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
    @keyframes load-urunler {
        from {
            left: -10px;
        }
        to   {
            left: 100%;
        }
    }
    .card{
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        box-shadow: 0 0px rgba(0,0,0,0.2);
    }
    .urunler_ref{
        transition: .2s;
    }
    /* belirli sure drag goruntulemek icin bildiri popup-i gosterilecek START */
    .drag-goruntule-bildirim{
        position: absolute;
        top: -125px;
        font-size: 14px;
        left: 0px;
        max-width: 240px;
        border: 1px solid #4285F4;
        border-radius: 5px;
        background: #4285F4;
        color: white;
        padding: 10px 40px 10px 10px;
        box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
        animation: bounce 1s infinite alternate;
        -webkit-animation: bounce 1s infinite alternate;
    }
    @keyframes bounce {
        from {
            transform: translateY(5px);
        }
        to {
            transform: translateY(-5px);
        }
    }
    @-webkit-keyframes bounce {
        from {
            transform: translateY(5px);
        }
        to {
            transform: translateY(-5px);
        }
    }
    .drag-goruntule-bildirim-yazi{
        padding-left: 10px;
        margin-right: -25px;
    }
    .triangle-down{
        position: absolute;
        top: 105px;
        left: 21px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 12px solid #4285F4;
    }
    .close{
        position: absolute;
        top: 9px;
        right: 10px;
        font-size: 20px;
        outline:none !important;
    }
    /* belirli sure filtre goruntulemek icin bildiri popup-i gosterilecek END */
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }
</style>


