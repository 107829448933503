export const siteName = "Adadakiler Sanal Market";
export const siteNameUpper = "ADADAKİLER SANAL MARKET";

export const siteMailList = [
  {
    mail: "adadakiler@alkid.com.tr",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [
  { phone: "0 (543) 211 03 09", info: "" },


];

export const eklenenMiktarKatsayisi = 0.1;
