<template>
  <div>
    <Navbar />
    <BottomNavMenu />
    <!-- Bu component tum sayfalara eklenecek -->

    <div class="row">
      <div class="col-3 gruppanel">
  <GrupSolMenu/>
      </div>
        <div class="widthfull">
            <Slide />
    
        </div>
    </div>

    <Gruplar />
    <SepetButton />

    <Vitrin />
    <!-- <Haftaninfirsatlari @sepeteekle="tumSepet.push($event)" /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import BottomNavMenu from "@/components/BottomNavMenu";
import Gruplar from "@/components/Gruplar";
import GrupSolMenu from "@/components/GrupSolMenu"
import SepetButton from "@/components/SepetButton";
import Slide from "@/components/Slide";
import Vitrin from "@/components/Vitrin";
// import Haftaninfirsatlari from "@/components/Haftaninfirsatlari"
import Footer from "@/components/Footer";


export default {
  components: {
    Navbar,
    BottomNavMenu,
    Gruplar,
    SepetButton,
    Slide,
    Vitrin,
    GrupSolMenu,
    // Haftaninfirsatlari,
    Footer,
  },
};
</script>


<style scoped>
[v-cloak] > * { display:none; }
 @media (max-width : 3000px){
    
     
        img{ width: 63vw !important; }
        .gruppanel{ width: 25%; }
        .widthfull {width: 75% }
    }
    @media (max-width : 990px){
        img{ width: 95vw !important; }
        .gruppanel{ display: none;}
        .widthfull {width: 100% !important; margin-left: 30px !important;  margin-right: 30px !important;}
    }
    @media (max-width : 768px){
       
      
    
    }
    @media (max-width : 500px){
        img{ width: 90vw !important; }
        

    }
   

    



    </style>
